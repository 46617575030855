<template>
  <TemplatePlateComp />
</template>

<script>
import TemplatePlateComp from "@/layouts/TemplatePlateComp.vue";

export default {
  name: "TemplatePlate",
  components: { TemplatePlateComp },
};
</script>
